import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import * as ons from "onsenui";

@Component({
  selector: 'actions-component',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit() {

  }
}
