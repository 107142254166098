<ons-page>
  <ons-card>
    <div class="title">
      Mijn Team
    </div>
    <div class="content">
      <div class="label">
        Selecteer een team:
      </div>
      <div class="label">
        * het laatst geselecteerde team wordt onthouden
      </div>
      <ons-select modifier="underbar" (change)="onTeamChange($event)">
        <option value="-1" [selected]="!selectedTeam ? true : false">-</option>
        <option *ngFor="let team of Teams" [value]="team.id" [selected]="selectedTeam ? (selectedTeam.id === team.id) : false">{{team.name}}</option>
      </ons-select>
    </div>
  </ons-card>

  <ons-card *ngIf="allMatchesOfTeam">
    <div class="title">
      {{SelectedTeamName}}
    </div>
    <div class="content">
      <div class="label">* duur = in minuten</div>
      <div class="label">* draai het scherm voor scores</div>
      <br />
      <ons-row class="header">
        <ons-col width="15%">Tijd</ons-col>
        <ons-col width="15%">Duur</ons-col>
        <ons-col>Veld</ons-col>
        <ons-col>Tegenstander</ons-col>
        <ons-col width="15%" class="score">Score</ons-col>
      </ons-row>
      <ons-row *ngFor="let match of allMatchesOfTeam" [ngClass]="{'current-time': match.startingHour === currentHours}">
        <ons-col width="15%">{{match.startingHour}}:{{match.startingMinute.pad(2)}}</ons-col>
        <ons-col width="15%">{{match.durationInMinutes}}</ons-col>
        <ons-col>{{getFieldNameFromId(match.fieldId)}}</ons-col>
        <ons-col>{{getOpponentNameFromMatch(match)}}</ons-col>
        <ons-col width="15%" class="score">5 - 8</ons-col>
      </ons-row>


      <!--<div class="flex-content-container">-->
        <!--<div class="row header">-->
          <!--<div class="col-auto">Tijd</div>-->
          <!--<div class="col-auto">Duur</div>-->
          <!--<div class="col">Veld</div>-->
          <!--<div class="col">Tegenstander</div>-->
          <!--<div class="col-auto score">Score</div>-->
        <!--</div>-->

        <!--<div *ngFor="let match of allMatchesOfTeam" [ngClass]="{'current-time': match.startingHour === currentHours, 'row': true, 'row-nonheader': true}">-->
          <!--<div class="col-auto">{{match.startingHour}}:{{match.startingMinute.pad(2)}}</div>-->
          <!--<div class="col-auto">{{match.durationInMinutes}}</div>-->
          <!--<div class="col">{{getFieldNameFromId(match.fieldId)}}</div>-->
          <!--<div class="col">{{getOpponentNameFromMatch(match)}}</div>-->
          <!--<div class="col-auto score">5 - 8</div>-->
        <!--</div>-->
      <!--</div>-->

    </div>
  </ons-card>



</ons-page>
