import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import * as ons from "onsenui";

@Component({
  selector: 'pricelist-component',
  templateUrl: './pricelist.component.html',
  styleUrls: ['./pricelist.component.scss']
})
export class PriceListComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit() {

  }
}
