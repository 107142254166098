import {Field, Team, TeamEvent} from "@core/model/team.model";

export class Constants {
  public static ERROR_OBJECTS: Object = {
    required: '{{field}} is required',
    email: 'Please enter valid email'
  };
  public static MONTH_STRINGS: string[] = ["Januari", "Februari", "March", "April", "May", "June", "Juli", "August", "September", "October", "November", "December"];
  public static TEAMS: Team[] = [
    {id: 1, name: "M.D.D. Equinox & TSG 51’45", poules: ["A"]},
    {id: 2, name: "O.D.D. Schanulleke & A.H.D. Festglede", poules: ["A"]},
    {id: 3, name: "DD Persephone & E.H.D. Omega", poules: ["A"]},
    {id: 4, name: "La Cucaracha & O.M.S.G. Plutarchus", poules: ["A"]},
    {id: 5, name: "M.D.D. Red Devil & WASSADAMO", poules: ["A"]},
    {id: 6, name: "O.D.D.M. Allicht & Ouwe Tijers", poules: ["B"]},
    {id: 7, name: "O.D.D.M. Les Sacripantes & O.H.D. Bonobo", poules: ["B"]},
    {id: 8, name: "Nos Ergo & M.H.D. Epicurus", poules: ["B"]},
    {id: 9, name: "A.D.D. Phylla & A.I. Forseti.", poules: ["B"]},
    {id: 10, name: "Darfinkar & MHG Librium", poules: ["B"]},
    {id: 11, name: "O.H.M. Que Pasa & O.D.D. Pinkwins", poules: ["C"]},
    {id: 12, name: "M.D.D. Ex Aequo & E.W.D Hephaestus", poules: ["C"]},
    {id: 13, name: "Ipse Dixit & M.H.D. Vloeibaar Goud", poules: ["C"]},
    {id: 14, name: "MJDD Victoria & M.H.V. Viramitas", poules: ["C"]},
    {id: 15, name: "Ad Phallus '96 & Ad Vulva '97", poules: ["C"]},
    {id: 16, name: "Misspuut Pandora & Upper Ten", poules: ["D"]},
    {id: 17, name: "O.D.D. Diablo & MHS Annorlunda", poules: ["D"]},
    {id: 18, name: "DD Topaas & D.D.A.M.T. Meridies", poules: ["D"]},
    {id: 19, name: "DD Phenix & Watergeuzen", poules: ["D"]},
    {id: 20, name: "M.D.D. Cumbonadea & HG Sunergos", poules: ["D"]},
    {id: 21, name: "O.D.D. Femmes-Tastiques & Plus Est Bibendum", poules: ["E"]},
    {id: 22, name: "O.D.D. Hesperides & M.H.D. Pisimopsantee", poules: ["E"]},
    {id: 23, name: "Po'di Ragazze & Chic Sat", poules: ["E"]},
    {id: 24, name: "O.D.D. Medusa & O.H.D. Spiritus Vitalis", poules: ["E"]},
    {id: 25, name: "a.d.d. Sirene & MHD Praetor", poules: ["E"]},
    {id: 26, name: "D.D. Ritz & M.H.D. Nondejuke", poules: ["F"]},
    {id: 27, name: "D.D. Les Must & O.H.D. Silenus", poules: ["F"]},
    {id: 28, name: "D.D. Maverick & E.H.D. Mezekouw", poules: ["F"]},
    {id: 29, name: "D.D. Minx & M.H.D. Ambiorix", poules: ["F"]},
    {id: 30, name: "E.M.O.D. Kokkepel & M.S.R.G. Maraboes", poules: ["F"]},
    {id: 31, name: "O.D.D. Bon'Aparte & Sogart Experiat", poules: ["G"]},
    {id: 32, name: "M.D.G. Nyneve & F.I.E.T.S", poules: ["G"]},
    {id: 33, name: "O.D.D. Missdaad & Adrianus", poules: ["G"]},
    {id: 34, name: "O.D.D. Sororitas & O.H.D. Perikles", poules: ["G"]},
    {id: 35, name: "H.D. Nexus & DD Samodivi", poules: ["G"]},
    {id: 36, name: "D.D. Chloe & M.H.D Rhino", poules: ["H"]},
    {id: 37, name: "O.M.G.M. Max & O.M.H.D. e causa Ignota", poules: ["H"]},
    {id: 38, name: "D.D. Adelphi & Alcibiades", poules: ["H"]},
    {id: 39, name: "A.D.D. Popino & Pegasus", poules: ["H"]},
    {id: 40, name: "M.D.G. Linque & Fabras", poules: ["H"]},

    {id: 41, name: "Winnaar A", poules: []},
    {id: 43, name: "Winnaar B", poules: []},
    {id: 45, name: "Winnaar C", poules: []},
    {id: 47, name: "Winnaar D", poules: []},
    {id: 42, name: "Winnaar  E", poules: []},
    {id: 44, name: "Winnaar  F", poules: []},
    {id: 46, name: "Winnaar  G", poules: []},
    {id: 48, name: "Winnaar  H", poules: []},
    {id: 49, name: "Winnaar ¼ finale 1", poules: []},
    {id: 51, name: "Winnaar ¼ finale 2", poules: []},
    {id: 50, name: "Winnaar ¼ finale 3", poules: []},
    {id: 52, name: "Winnaar ¼ finale 4", poules: []},
    {id: 53, name: "Winnaar ½ finale 1", poules: []},
    {id: 54, name: "Winnaar ½ finale 2", poules: []},
  ];

  public static FIELDS: Field[] = [
    {id: 1, name: "Veld Heineken"},
    {id: 2, name: "Veld Beurs"},
    {id: 3, name: "Veld FM Kaffee"},
    {id: 4, name: "Veld Fortas"},
    {id: 5, name: "Veld Code 043"},
    {id: 6, name: "Veld Carnal"},
    {id: 7, name: "Veld Integrand"},
    // {id: 99, name: "Bier Eiland"},
    // {id: 98, name: "Pizza"},
    // {id: 97, name: "Friet"},
    // {id: 96, name: "Tent Stage"},
    // {id: 95, name: "Tent Stage Bar"},
    // {id: 94, name: "WC"},
    // {id: 93, name: "Bonnen"},
    // {id: 92, name: "EHBO"},
    // {id: 91, name: "Ingang"},
    // {id: 90, name: "Wedstrijd Tafel"},
     {id: 89, name: "Sangria Bar"},
    // {id: 88, name: "Main Stage"},
    {id: 87, name: "Wiggely Woggely"},
  ];

  public static MATCHES: TeamEvent[] = [
    {id:1   ,description: '', team1Id: 1, team2Id: 2, fieldId: 1, startingHour: 16, startingMinute: 0, durationInMinutes: 30},
    {id:2   ,description: '', team1Id: 40, team2Id: 36, fieldId: 1, startingHour: 16, startingMinute: 30, durationInMinutes: 30},
    {id:3   ,description: '', team1Id: 3, team2Id: 4, fieldId: 1, startingHour: 17, startingMinute: 0, durationInMinutes: 30},
    {id:4   ,description: '', team1Id: 1, team2Id: 5, fieldId: 1, startingHour: 17, startingMinute: 30, durationInMinutes: 30},
    {id:5   ,description: '', team1Id: 2, team2Id: 3, fieldId: 1, startingHour: 18, startingMinute: 0, durationInMinutes: 30},
    {id:6   ,description: '', team1Id: 4, team2Id: 5, fieldId: 1, startingHour: 18, startingMinute: 30, durationInMinutes: 30},
    {id:7   ,description: '', team1Id: 1, team2Id: 3, fieldId: 1, startingHour: 19, startingMinute: 0, durationInMinutes: 30},
    {id:8   ,description: '', team1Id: 2, team2Id: 4, fieldId: 1, startingHour: 19, startingMinute: 30, durationInMinutes: 30},
    {id:9   ,description: '', team1Id: 5, team2Id: 10, fieldId: 1, startingHour: 20, startingMinute: 0, durationInMinutes: 30},
    {id:10  ,description: '', team1Id: 6, team2Id: 7, fieldId: 2, startingHour: 16, startingMinute: 0, durationInMinutes: 30},
    {id:11  ,description: '', team1Id: 8, team2Id: 9, fieldId: 2, startingHour: 16, startingMinute: 30, durationInMinutes: 30},
    {id:12  ,description: '', team1Id: 37, team2Id: 38, fieldId: 2, startingHour: 17, startingMinute: 0, durationInMinutes: 30},
    {id:13  ,description: '', team1Id: 6, team2Id: 10, fieldId: 2, startingHour: 17, startingMinute: 30, durationInMinutes: 30},
    {id:14  ,description: '', team1Id: 39, team2Id: 35, fieldId: 2, startingHour: 18, startingMinute: 0, durationInMinutes: 30},
    {id:15  ,description: '', team1Id: 9, team2Id: 10, fieldId: 2, startingHour: 18, startingMinute: 30, durationInMinutes: 30},
    {id:16  ,description: '', team1Id: 6, team2Id: 8, fieldId: 2, startingHour: 19, startingMinute: 0, durationInMinutes: 30},
    {id:17  ,description: '', team1Id: 7, team2Id: 9, fieldId: 2, startingHour: 19, startingMinute: 30, durationInMinutes: 30},
    {id:18  ,description: '', team1Id: 7, team2Id: 8, fieldId: 2, startingHour: 20, startingMinute: 0, durationInMinutes: 30},
    {id:19  ,description: '', team1Id: 11, team2Id: 12, fieldId: 3, startingHour: 16, startingMinute: 0, durationInMinutes: 30},
    {id:20  ,description: '', team1Id: 13, team2Id: 14, fieldId: 3, startingHour: 16, startingMinute: 30, durationInMinutes: 30},
    {id:21  ,description: '', team1Id: 11, team2Id: 15, fieldId: 3, startingHour: 17, startingMinute: 0, durationInMinutes: 30},
    {id:22  ,description: '', team1Id: 40, team2Id: 37, fieldId: 3, startingHour: 17, startingMinute: 30, durationInMinutes: 30},
    {id:23  ,description: '', team1Id: 12, team2Id: 13, fieldId: 3, startingHour: 18, startingMinute: 0, durationInMinutes: 30},
    {id:24  ,description: '', team1Id: 14, team2Id: 15, fieldId: 3, startingHour: 18, startingMinute: 30, durationInMinutes: 30},
    {id:25  ,description: '', team1Id: 12, team2Id: 14, fieldId: 3, startingHour: 19, startingMinute: 0, durationInMinutes: 30},
    {id:26  ,description: '', team1Id: 11, team2Id: 13, fieldId: 3, startingHour: 19, startingMinute: 30, durationInMinutes: 30},
    {id:27  ,description: '', team1Id: 16, team2Id: 17, fieldId: 4, startingHour: 16, startingMinute: 0, durationInMinutes: 30},
    {id:28  ,description: '', team1Id: 18, team2Id: 19, fieldId: 4, startingHour: 16, startingMinute: 30, durationInMinutes: 30},
    {id:29  ,description: '', team1Id: 16, team2Id: 20, fieldId: 4, startingHour: 17, startingMinute: 0, durationInMinutes: 30},
    {id:30  ,description: '', team1Id: 17, team2Id: 18, fieldId: 4, startingHour: 17, startingMinute: 30, durationInMinutes: 30},
    {id:31  ,description: '', team1Id: 19, team2Id: 20, fieldId: 4, startingHour: 18, startingMinute: 0, durationInMinutes: 30},
    {id:32  ,description: '', team1Id: 17, team2Id: 19, fieldId: 4, startingHour: 18, startingMinute: 30, durationInMinutes: 30},
    {id:33  ,description: '', team1Id: 16, team2Id: 18, fieldId: 4, startingHour: 19, startingMinute: 0, durationInMinutes: 30},
    {id:34  ,description: '', team1Id: 15, team2Id: 20, fieldId: 4, startingHour: 19, startingMinute: 30, durationInMinutes: 30},
    {id:35  ,description: '', team1Id: 21, team2Id: 22, fieldId: 5, startingHour: 16, startingMinute: 0, durationInMinutes: 30},
    {id:36  ,description: '', team1Id: 23, team2Id: 24, fieldId: 5, startingHour: 16, startingMinute: 30, durationInMinutes: 30},
    {id:37  ,description: '', team1Id: 21, team2Id: 25, fieldId: 5, startingHour: 17, startingMinute: 0, durationInMinutes: 30},
    {id:38  ,description: '', team1Id: 22, team2Id: 23, fieldId: 5, startingHour: 17, startingMinute: 30, durationInMinutes: 30},
    {id:39  ,description: '', team1Id: 24, team2Id: 25, fieldId: 5, startingHour: 18, startingMinute: 0, durationInMinutes: 30},
    {id:40  ,description: '', team1Id: 22, team2Id: 24, fieldId: 5, startingHour: 18, startingMinute: 30, durationInMinutes: 30},
    {id:41  ,description: '', team1Id: 25, team2Id: 30, fieldId: 5, startingHour: 19, startingMinute: 0, durationInMinutes: 30},
    {id:42  ,description: '', team1Id: 21, team2Id: 23, fieldId: 5, startingHour: 19, startingMinute: 30, durationInMinutes: 30},
    {id:43  ,description: '', team1Id: 26, team2Id: 27, fieldId: 6, startingHour: 16, startingMinute: 0, durationInMinutes: 30},
    {id:44  ,description: '', team1Id: 28, team2Id: 29, fieldId: 6, startingHour: 16, startingMinute: 30, durationInMinutes: 30},
    {id:45  ,description: '', team1Id: 26, team2Id: 30, fieldId: 6, startingHour: 17, startingMinute: 0, durationInMinutes: 30},
    {id:46  ,description: '', team1Id: 27, team2Id: 28, fieldId: 6, startingHour: 17, startingMinute: 30, durationInMinutes: 30},
    {id:47  ,description: '', team1Id: 29, team2Id: 30, fieldId: 6, startingHour: 18, startingMinute: 0, durationInMinutes: 30},
    {id:48  ,description: '', team1Id: 27, team2Id: 29, fieldId: 6, startingHour: 18, startingMinute: 30, durationInMinutes: 30},
    {id:49  ,description: '', team1Id: 26, team2Id: 28, fieldId: 6, startingHour: 19, startingMinute: 0, durationInMinutes: 30},
    {id:50  ,description: '', team1Id: 31, team2Id: 35, fieldId: 6, startingHour: 19, startingMinute: 30, durationInMinutes: 30},
    {id:51  ,description: '', team1Id: 38, team2Id: 39, fieldId: 6, startingHour: 20, startingMinute: 0, durationInMinutes: 30},
    {id:52  ,description: '', team1Id: 31, team2Id: 32, fieldId: 7, startingHour: 16, startingMinute: 0, durationInMinutes: 30},
    {id:53  ,description: '', team1Id: 33, team2Id: 34, fieldId: 7, startingHour: 16, startingMinute: 30, durationInMinutes: 30},
    {id:54  ,description: '', team1Id: 31, team2Id: 33, fieldId: 7, startingHour: 17, startingMinute: 0, durationInMinutes: 30},
    {id:55  ,description: '', team1Id: 32, team2Id: 34, fieldId: 7, startingHour: 17, startingMinute: 30, durationInMinutes: 30},
    {id:56  ,description: '', team1Id: 36, team2Id: 38, fieldId: 7, startingHour: 18, startingMinute: 0, durationInMinutes: 30},
    {id:57  ,description: '', team1Id: 35, team2Id: 34, fieldId: 7, startingHour: 18, startingMinute: 30, durationInMinutes: 30},
    {id:58  ,description: '', team1Id: 32, team2Id: 33, fieldId: 7, startingHour: 19, startingMinute: 0, durationInMinutes: 30},
    {id:59  ,description: '', team1Id: 40, team2Id: 39, fieldId: 7, startingHour: 19, startingMinute: 30, durationInMinutes: 30},
    {id:60  ,description: '', team1Id: 36, team2Id: 37, fieldId: 7, startingHour: 20, startingMinute: 0, durationInMinutes: 30},
    {id:61  ,description: '', team1Id: 3, team2Id: 4, fieldId: 87, startingHour: 16, startingMinute: 0, durationInMinutes: 15},
    {id:62  ,description: '', team1Id: 5, team2Id: 10, fieldId: 87, startingHour: 16, startingMinute: 15, durationInMinutes: 15},
    {id:63  ,description: '', team1Id: 1, team2Id: 2, fieldId: 87, startingHour: 16, startingMinute: 30, durationInMinutes: 15},
    {id:64  ,description: '', team1Id: 6, team2Id: 7, fieldId: 87, startingHour: 16, startingMinute: 45, durationInMinutes: 15},
    {id:65  ,description: '', team1Id: 8, team2Id: 9, fieldId: 87, startingHour: 17, startingMinute: 0, durationInMinutes: 15},
    {id:66  ,description: '', team1Id: 12, team2Id: 13, fieldId: 87, startingHour: 17, startingMinute: 15, durationInMinutes: 15},
    {id:67  ,description: '', team1Id: 11, team2Id: 14, fieldId: 87, startingHour: 17, startingMinute: 30, durationInMinutes: 15},
    {id:68  ,description: '', team1Id: 16, team2Id: 19, fieldId: 87, startingHour: 17, startingMinute: 45, durationInMinutes: 15},
    {id:69  ,description: '', team1Id: 17, team2Id: 18, fieldId: 87, startingHour: 18, startingMinute: 0, durationInMinutes: 15},
    {id:70  ,description: '', team1Id: 21, team2Id: 22, fieldId: 87, startingHour: 18, startingMinute: 15, durationInMinutes: 15},
    {id:71  ,description: '', team1Id: 23, team2Id: 25, fieldId: 87, startingHour: 18, startingMinute: 30, durationInMinutes: 15},
    {id:72  ,description: '', team1Id: 26, team2Id: 28, fieldId: 87, startingHour: 18, startingMinute: 45, durationInMinutes: 15},
    {id:73  ,description: '', team1Id: 27, team2Id: 29, fieldId: 87, startingHour: 19, startingMinute: 0, durationInMinutes: 15},
    {id:74  ,description: '', team1Id: 31, team2Id: 34, fieldId: 87, startingHour: 19, startingMinute: 15, durationInMinutes: 15},
    {id:75  ,description: '', team1Id: 32, team2Id: 33, fieldId: 87, startingHour: 19, startingMinute: 30, durationInMinutes: 15},
    {id:76  ,description: '', team1Id: 36, team2Id: 37, fieldId: 87, startingHour: 19, startingMinute: 45, durationInMinutes: 15},
    {id:77  ,description: '', team1Id: 15, team2Id: 20, fieldId: 87, startingHour: 20, startingMinute: 0, durationInMinutes: 15},
    {id:78  ,description: '', team1Id: 24, team2Id: 30, fieldId: 87, startingHour: 20, startingMinute: 15, durationInMinutes: 15},
    {id:79  ,description: '', team1Id: 35, team2Id: 39, fieldId: 87, startingHour: 20, startingMinute: 30, durationInMinutes: 15},
    {id:80  ,description: '', team1Id: 38, team2Id: 40, fieldId: 87, startingHour: 20, startingMinute: 45, durationInMinutes: 15},
    {id:81  ,description: '', team1Id: 38, team2Id: 37, fieldId: 89, startingHour: 16, startingMinute: 0, durationInMinutes: 15},
    {id:82  ,description: '', team1Id: 35, team2Id: 36, fieldId: 89, startingHour: 16, startingMinute: 15, durationInMinutes: 15},
    {id:83  ,description: '', team1Id: 32, team2Id: 31, fieldId: 89, startingHour: 16, startingMinute: 30, durationInMinutes: 15},
    {id:84  ,description: '', team1Id: 30, team2Id: 27, fieldId: 89, startingHour: 16, startingMinute: 45, durationInMinutes: 15},
    {id:85  ,description: '', team1Id: 29, team2Id: 28, fieldId: 89, startingHour: 17, startingMinute: 0, durationInMinutes: 15},
    {id:86  ,description: '', team1Id: 22, team2Id: 23, fieldId: 89, startingHour: 17, startingMinute: 15, durationInMinutes: 15},
    {id:87  ,description: '', team1Id: 26, team2Id: 25, fieldId: 89, startingHour: 17, startingMinute: 30, durationInMinutes: 15},
    {id:88  ,description: '', team1Id: 20, team2Id: 15, fieldId: 89, startingHour: 17, startingMinute: 45, durationInMinutes: 15},
    {id:89  ,description: '', team1Id: 34, team2Id: 33, fieldId: 89, startingHour: 18, startingMinute: 0, durationInMinutes: 15},
    {id:90  ,description: '', team1Id: 18, team2Id: 16, fieldId: 89, startingHour: 18, startingMinute: 15, durationInMinutes: 15},
    {id:91  ,description: '', team1Id: 11, team2Id: 13, fieldId: 89, startingHour: 18, startingMinute: 30, durationInMinutes: 15},
    {id:92  ,description: '', team1Id: 39, team2Id: 40, fieldId: 89, startingHour: 18, startingMinute: 45, durationInMinutes: 15},
    {id:93  ,description: '', team1Id: 21, team2Id: 24, fieldId: 89, startingHour: 19, startingMinute: 0, durationInMinutes: 15},
    {id:94  ,description: '', team1Id: 17, team2Id: 19, fieldId: 89, startingHour: 19, startingMinute: 15, durationInMinutes: 15},
    {id:95  ,description: '', team1Id: 14, team2Id: 12, fieldId: 89, startingHour: 19, startingMinute: 30, durationInMinutes: 15},
    {id:96  ,description: '', team1Id: 10, team2Id: 6, fieldId: 89, startingHour: 19, startingMinute: 45, durationInMinutes: 15},
    {id:97  ,description: '', team1Id: 9, team2Id: 4, fieldId: 89, startingHour: 20, startingMinute: 0, durationInMinutes: 15},
    {id:98  ,description: '', team1Id: 1, team2Id: 3, fieldId: 89, startingHour: 20, startingMinute: 15, durationInMinutes: 15},
    {id:99  ,description: '', team1Id: 2, team2Id: 5, fieldId: 89, startingHour: 20, startingMinute: 30, durationInMinutes: 15},
    {id:100 ,description: '', team1Id: 8, team2Id: 7, fieldId: 89, startingHour: 20, startingMinute: 45, durationInMinutes: 15},

    {id:103 ,description: '', team1Id: 41, team2Id: 42, fieldId: 1, startingHour: 20, startingMinute: 45, durationInMinutes: 30},
    {id:104 ,description: '', team1Id: 43, team2Id: 44, fieldId: 2, startingHour: 20, startingMinute: 45, durationInMinutes: 30},
    {id:105 ,description: '', team1Id: 45, team2Id: 46, fieldId: 3, startingHour: 20, startingMinute: 45, durationInMinutes: 30},
    {id:106 ,description: '', team1Id: 47, team2Id: 48, fieldId: 4, startingHour: 20, startingMinute: 45, durationInMinutes: 30},

    {id:108 ,description: '', team1Id: 49, team2Id: 50, fieldId: 1, startingHour: 21, startingMinute: 15, durationInMinutes: 30},
    {id:109 ,description: '', team1Id: 51, team2Id: 52, fieldId: 2, startingHour: 21, startingMinute: 15, durationInMinutes: 30},

    {id:111 ,description: '', team1Id: 53, team2Id: 54, fieldId: 1, startingHour: 22, startingMinute: 0, durationInMinutes: 30},
  ];
}
