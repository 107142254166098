<ons-page>
  <ons-card>
    <div class="title">
      Prijslijst
    </div>
  </ons-card>

  <ons-card>
    <div class="title">
    </div>
    <div class="content">
      <ons-row class="header">
        <ons-col>Product</ons-col>
        <ons-col>Prijs (&euro;)</ons-col>
      </ons-row>
      <ons-row>
        <ons-col>Bier</ons-col>
        <ons-col>&euro;2 per glas</ons-col>
      </ons-row>
      <ons-row>
        <ons-col>Wijn</ons-col>
        <ons-col>&euro;2 per glas</ons-col>
      </ons-row>
      <ons-row>
        <ons-col>Wodka</ons-col>
        <ons-col>&euro;2 per fles</ons-col>
      </ons-row>
      <ons-row>
        <ons-col>Gebakken lucht</ons-col>
        <ons-col>&euro;29.99 per liter</ons-col>
      </ons-row>
    </div>
  </ons-card>


</ons-page>
