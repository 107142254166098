<ons-page>
  <ons-card>
    <div class="title">
      Line-up
    </div>
  </ons-card>

  <div class="artists col-1-1 no-p">
    <div class="col-1-3 sm-col-1-2 xs-col-1-1">
      <a href="javascript:void(0)" class="event-item col-1-1 no-p">
        <figure class="wrapper">
          <img src="https://partyflock.nl/images/artist/91444_330x495_399371/Kyle-Corvo.jpg" alt="DVS1">
          <h5>Kyle Corvo - 12:00 tm 13:00</h5>
        </figure>
      </a>
    </div>
    <div class="col-1-3 sm-col-1-2 xs-col-1-1">
      <a href="javascript:void(0)" class="event-item col-1-1 no-p">
        <figure class="wrapper">
          <img src="http://websites.b1services.com/wp-content/uploads/2017/10/mark-moore-800x450.jpg" alt="Perc">
          <h5>Marc Moore - 14:30 tm 17:00</h5>
        </figure>
      </a>
    </div>
    <div class="col-1-3 sm-col-1-2 xs-col-1-1">
      <a href="javascript:void(0)" class="event-item col-1-1 no-p">
        <figure class="wrapper">
          <img src="https://www.soenda.net/cache/_158/-0-0-400-400-gray.jpg" alt="Attention Deficit Disorder">
          <h5>Cooperated Souls - 17:30 tm 18:30</h5>
        </figure>
      </a>
    </div>
    <div class="col-1-3 sm-col-1-2 xs-col-1-1">
      <a href="javascript:void(0)" class="event-item col-1-1 no-p">
        <figure class="wrapper">
          <img src="https://www.soenda.net/cache/_175/-0-0-400-400-gray.png" alt="Talismann (live)">
          <h5>Perc </h5>
        </figure>
      </a>
    </div>
    <div class="col-1-3 sm-col-1-2 xs-col-1-1">
      <a href="javascript:void(0)" class="event-item col-1-1 no-p">
        <figure class="wrapper">
          <img src="https://www.soenda.net/cache/_177/-0-0-400-400-gray.png" alt="Jennifer Cardini">
          <h5>Perc </h5>
        </figure>
      </a>
    </div>
    <div class="col-1-3 sm-col-1-2 xs-col-1-1">
      <a href="javascript:void(0)" class="event-item col-1-1 no-p">
        <figure class="wrapper">
          <img src="https://www.soenda.net/cache/_162/-0-0-400-400-gray.png" alt="Stranger">
          <h5>Perc </h5>
        </figure>
      </a>
    </div>
  </div>


</ons-page>
